import { Component, Prop, Watch } from "vue-property-decorator";
import CartView from './CartView';

@Component
export default class CartViewB2C extends CartView {
    @Prop()
    private hasCampaignVoucherEnabled!: boolean;

    private renderOnlyOnPageload: boolean = false;
    
    constructor() {
        
        super();
    }
    public beginCheckoutGA4Event(): void {

        if(this.renderOnlyOnPageload) {
            return;
        }

        // Fire event on page load
        this.$gtm.trackView("Checkout", this.cart.checkoutUrl);
    
        let gtmItems: any[] = [];
        
        if (window.dataLayer && this.cart.orderRows) {
            this.cart.orderRows.forEach((row) => {
                const item = {
                    item_name: row.productName, 
                    item_id: row.articleNumber,  
                    price: parseFloat(row.price), 
                    item_category: row.category, 
                    item_variant: row.name,
                    quantity: row.quantity
                };
                gtmItems.push(item);
            });

            const currentUrl = window.location.href;

            let currency: string;

            if (currentUrl.includes("tapwell.se")) {
                currency = "SEK";
            } else if (currentUrl.includes("tapwell.no")) {
                currency = "NOK";
            } else if (currentUrl.includes("tapwell.dk")) {
                currency = "DKK";
            } else {
                currency = "EUR"; 
            }
       
            const totalValue = this.calculateTotalValue(gtmItems);
    
            if (gtmItems.length > 0) {
                const dataLayerObject = {
                    event: "begin_checkout",
                    ecommerce: {
                        currency: currency, 
                        value: totalValue, 
                        items: gtmItems
                    }
                };
    
                window.dataLayer.push(dataLayerObject);
            }
        }
        this.renderOnlyOnPageload = true;
    }
    
    public calculateTotalValue(items: any[]): number {
        const total = items.reduce((acc, item) => {
            return acc + (item.price * item.quantity);
        }, 0);
        return total;
    }

    @Watch("cart")
    public onCartChanged() {
        if (this.cart) {
            this.beginCheckoutGA4Event();
        }
    }

}
